




import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

type AsyncComponentImport = () => Promise<typeof import('*.vue')>

interface MagazineTemplates {
  [key: string]: AsyncComponentImport
}

export default defineComponent({
  name: 'MagazineSingle',
  components: {},
  props: {},

  setup() {
    const { template } = useGetters(['template'])

    const templates: MagazineTemplates = {
      /* eslint-disable quote-props */
      // Subcategories:
      'seen-on-tv': () => import('@/views/magazine/subcategory/Tv.vue'),
      'personality-category': () =>
        import('@/views/magazine/subcategory/Personality.vue'),
      // Articles:
      experience: () => import('@/views/magazine/single/Experience.vue'),
      podcast: () => import('@/views/magazine/single/Podcast.vue'),
      single: () => import('@/views/magazine/single/Default.vue'),
      // OLD
      blog: () => import('@/views/magazine/single/Default.vue'),
      /* eslint-enable quote-props */
    }

    return {
      template,
      templates,
    }
  },
})
